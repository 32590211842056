import React from 'react'

const InnerPage = ({ title, pics = null, content }) => {
  return (
    <div className="space-y-5">
      <h3 className="subtitle text-2xl">{title}</h3>
      {content}
      {pics}
    </div>
  )
}

export default InnerPage
