import React from 'react'

import Map from '../components/Map'

const Ubication = () => {
  return (
    <div className="flex flex-wrap">
      <div className="w-4/5 mx-auto my-5 p-4 rounded bg-blue-100 shadow-xl sm:w-2/3 sm:p-8 md:w-80 md:flex-initial dark:bg-blue-100 dark:text-black">
        <h3 className="subtitle pb-3 text-2xl text-center">Estamos en</h3>
        <aside className="flex-1">
          <p className="subtitle">Dirección:</p>
          <p className="data">Antigua Carretera a la tina N° 202 - Sullana</p>
          <p className="subtitle">Teléfono:</p>
          <p className="data">073-358548</p>
          <p className="subtitle">Celular:</p>
          <p className="data">920830231 / 964664985</p>
          <p className="subtitle">Correo electrónico:</p>
          <ul>
            <li className="data">ventas@rmfperu.com</li>
            <li className="data">rmfser@gmail.com</li>
          </ul>
        </aside>
      </div>
      <Map />
    </div>
  )
}

export default Ubication
