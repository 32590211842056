import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { handleFormSubmit } from '../helpers/submit-handler'

const ContactForm = () => {
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      company: '',
      message: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .max(15, 'Máximo 15 caracteres')
        .required('Campo requerido'),
      lastName: Yup.string()
        .max(20, 'Máximo 20 caracteres')
        .required('Campo requerido'),
      email: Yup.string()
        .email('Formato de email inválido')
        .required('Campo requerido'),
      phone: Yup.string()
        .matches(phoneRegExp, 'Deben ser dígitos')
        .min(9, 'Mínimo 9 caracteres')
        .required('Campo requerido'),
      company: Yup.string().max(20, 'Máximo 20 caracteres'),
      message: Yup.string(),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      console.log(JSON.stringify(values, null, 2))
      setSubmitting(true)
      resetForm()
      setSubmitting(false)
    },
  })

  return (
    <div className="w-3/4 mx-auto sm:w-1/2">
      <h2 className="title">Hablemos</h2>

      <form
        onSubmit={(e) => {
          handleFormSubmit(e, formik.values, formik.errors)
          formik.handleSubmit()
        }}
        className="gform space-y-2"
        method="POST"
        action="https://script.google.com/macros/s/AKfycbyE5iDp7Iar5fjoNCETwMbVSKBi-yKl0yOBSBtgE6asmAXqSvZK/exec"
      >
        <div className="hidden text-center thankyou_message">
          <p>
            ¡<em>Gracias</em> por tu mensaje! Te contactaremos a la brevedad
          </p>
        </div>

        <label htmlFor="firstName" className="subtitle block">
          Nombres
        </label>
        <input
          id="firstName"
          name="firstName"
          type="text"
          placeholder="Nombres"
          className="text-input"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.firstName}
        />
        {formik.touched.firstName && formik.errors.firstName ? (
          <div className="error">{formik.errors.firstName}</div>
        ) : null}

        <label htmlFor="lastName" className="subtitle block">
          Apellidos
        </label>
        <input
          id="lastName"
          name="lastName"
          placeholder="Apellidos"
          type="text"
          className="text-input"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.lastName}
        />
        {formik.touched.lastName && formik.errors.lastName ? (
          <div className="error">{formik.errors.lastName}</div>
        ) : null}

        <label htmlFor="email" className="subtitle block">
          Correo electrónico
        </label>
        <input
          id="email"
          name="email"
          placeholder="ejemplo@mail.com"
          type="email"
          className="text-input"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
        />
        {formik.touched.email && formik.errors.email ? (
          <div className="error">{formik.errors.email}</div>
        ) : null}

        <label htmlFor="phone" className="subtitle block">
          Teléfono / Celular
        </label>
        <input
          id="phone"
          name="phone"
          placeholder="987654321"
          type="text"
          className="text-input"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.phone}
        />
        {formik.touched.phone && formik.errors.phone ? (
          <div className="error">{formik.errors.phone}</div>
        ) : null}

        <label htmlFor="company" className="subtitle block">
          Empresa (opcional)
        </label>
        <input
          id="company"
          name="company"
          placeholder="Empresa"
          type="text"
          className="text-input"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.company}
        />
        {formik.touched.company && formik.errors.company ? (
          <div className="error">{formik.errors.company}</div>
        ) : null}

        <label htmlFor="message" className="subtitle block">
          Mensaje
        </label>
        <textarea
          id="message"
          name="message"
          as="textarea"
          className="text-input form-textarea"
          placeholder="Escribe tu mensaje..."
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.message}
        />

        <div className="flex sm:justify-center">
          <button type="submit" className="button sm:w-48">
            Enviar
          </button>
        </div>
      </form>
    </div>
  )
}

export default ContactForm
