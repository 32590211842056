import React from 'react'

import Hero from '../components/Hero'
import Description from '../components/Description'
import CallToAction from '../components/CallToAction'

const Home = ({ isDark }) => {
  return (
    <div className="wrapper pt-3 pb-10 space-y-10 md:space-y-20 sm:pt-24 md:pb-16 lg:pt-10">
      <Hero isDark={isDark} />
      <Description />
      <CallToAction />
    </div>
  )
}

export default Home
