import React from 'react'

import GridPics from '../../components/GridPics'
import DownloadBtn from '../../components/DownloadBtn'

const Optimizacion = () => {
  return (
    <div className="space-y-5">
      <p className="paragraph">
        “Proporcionando soluciones innovadoras permitiendo optimizar sus costos,
        mejorar la productividad y eficiencia teniendo presente el cuidado del
        medio ambiente acorde para su empresa, utilizando nuestra metodología
        RMF DVO Metodología RMF DVO: Buscamos determinar la mejor solución para
        sus necesidades. Determinando las fallas y estableciendo la solución más
        optima por medio de los diferentes procesos o servicios con los que
        cuenta nuestra empresa:
      </p>

      <h4 className="subtitle">Proceso de Reparación:</h4>
      <ul>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Soldadura MIG, TIG y
          Electrodo{' '}
        </li>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Reparación mecánica
        </li>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>
          Reparación con agentes químicos, adición de masillas y recubrimientos
        </li>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Corte, forja, rolado,
          plegado, fresado
        </li>
      </ul>
      <GridPics path="metodologia/reparacion" numOfPics={12} />

      <h4 className="subtitle">Proceso de Mantenimiento:</h4>
      <p>Determinar las fallas y establecer la mejor decisión </p>
      <GridPics path="metodologia/mantenimiento" numOfPics={8} />

      <h4 className="subtitle">Proceso de Fabricación:</h4>
      <p>Diseño y fabricación de piezas para la industria.</p>
      <ul>
        <li className="my-8">
          <i className="fas fa-chevron-right mr-2"></i>Fundición: Bronce, Hierro
          fundido, Hierro nodular, Aluminio
        </li>
        <GridPics path="metodologia/fabricacion-fundicion" numOfPics={25} />
        <li className="my-8">
          <i className="fas fa-chevron-right mr-2"></i>Confección: fabricación
          de piezas en material de acero al carbono, acero inoxidable, bronce,
          aluminio, etc por medio de procesos de soldadura, torneado.
        </li>
        <GridPics path="metodologia/fabricacion-confeccion" numOfPics={17} />
      </ul>

      <h4 className="subtitle">Proceso de Diagnostico:</h4>
      <p>
        Mediante la recopilación de información por medios visuales, por
        información brindada por el personal de nuestro cliente y por pruebas y
        ensayos, se logra tener una idea clara de acuerdo a las necesidades de
        nuestros clientes para poder brindarles la mejor solución
      </p>

      <h4 className="subtitle">Proceso de Validación:</h4>
      <ul>
        Con:
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Material lo validamos con
          ensayos mecánicos
        </li>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>
          Geometría de pieza validamos con: planos en AutoCAD o solid works,
          micrómetros de precisión
        </li>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Grados de acabado con
          rugosímetros
        </li>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Radios de curvatura con
          galgas{' '}
        </li>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>
          Precisiones de tolerancias de acuerdo a su aplicación con herramientas
          de precisión del orden de 0.001 mm y 0.0001 mm
        </li>
      </ul>

      <ul>
        Laboratorio:
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Maquina ensayos
          universales: tracción, compresión, alargamiento, estricción, análisis
          químicos, análisis metalográficos
        </li>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Ensayos destructivos para
          controlar las características físicas y mecánicas de las piezas.
        </li>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Durómetro digital: hv,
          hb,hrb,hrc
        </li>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Durómetro vickers: hv
        </li>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Microdurómetro hv
        </li>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Microscopio metalográfico
          con cámara digital
        </li>
      </ul>

      <ul>
        Metrología:
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Proyector perfiles con
          calculador geométrico
        </li>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Perfilómetro
        </li>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Rugosímetro
        </li>
      </ul>

      <h4 className="subtitle">Proceso de Optimización:</h4>
      <p>
        El objetivo de la optimización de mantenimiento es determinar las tareas
        de mantenimiento adecuadas que permitan minimizar{' '}
        <strong>el tiempo</strong> de inactividad del equipo y que proporcionen
        un uso más eficaz de los sistemas y recursos para asegurar los
        resultados deseados al menor costo posible, considerando todas las
        limitaciones
      </p>
      <p>
        Presentamos informe de probable causa de deterioro de pieza Planteamos
        alternativas de mejora o mantenimiento de tipo de material con el
        objetivo de mantener en operatividad el equipo y solo parar en tiempo
        programado
      </p>

      <div>
        <h4 className="subtitle my-8">
          Revisa algunos brochures de partes que podemos fabricar:
        </h4>
        <div className="flex justify-around flex-wrap">
          <DownloadBtn
            title="Partes de unidad de bombeo A/B"
            path="/brochures/brochure-unidades-bombeo.pdf"
          />
          <DownloadBtn
            title="Torres de destilación"
            path="/brochures/brochure-torre.pdf"
          />
          <DownloadBtn
            title="Productos para control de gases y otros"
            path="/brochures/brochure-productos-gas-petroleo.pdf"
          />
        </div>
      </div>
    </div>
  )
}

export default Optimizacion
