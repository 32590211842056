import React from 'react'

import DownloadBtn from '../../components/DownloadBtn'

const FabricacionPoleas = () => {
  return (
    <React.Fragment>
      <p className="paragraph">
        Somos fabricantes de poleas de aluminio de 8”, 10”, 12” y 14” con
        orificios de 1 “ y 1 ½” de un canal tipo A.
      </p>
      <p className="paragraph">
        A diferencia de las poleas comerciales, nuestras poleas cuentan con un
        diferenciador que consiste en un refuerzo de un bocín en material
        duraluminio, que le otorga un menor desgaste y una vida útil más larga
        en comparación a las existentes en el mercado.
      </p>
      <p className="paragraph">
        También contamos con una amplia gama de poleas en hierro, con poleas en
        perfil americano estrecho (3V, 5V Y 8V) como poleas en perfil americano
        clásico (perfil “A/B” y “C”).
      </p>
      <div className="flex justify-center">
        <DownloadBtn path="/brochures/catalogo-poleas.pdf" />
      </div>
    </React.Fragment>
  )
}

export default FabricacionPoleas
