import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Logo from '../components/Logo'
import { goTop } from '../helpers/go-top'

const Hero = ({ isDark }) => {
  const [isDesktop, setDesktop] = useState(window.innerWidth >= 1024)

  const updateMedia = () => {
    setDesktop(window.innerWidth >= 1024)
  }

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  return (
    <section
      className={`container bg-rmf rounded-3xl mx-auto p-5 space-y-5 ${
        isDark ? 'shadow-none' : 'shadow-2xl'
      } sm:w-11/12 sm:space-y-6 sm:py-8 sm:px-16`}
    >
      {!isDesktop && <Logo />}

      <div className="flex flex-col space-y-5 sm:space-y-10 lg:flex-row lg:space-y-0">
        <div className="w-full h-auto lg:w-1/3 lg:flex-1">
          <img
            className="w-full h-auto object-cover object-top rounded-2xl lg:h-full"
            src={process.env.PUBLIC_URL + '/img/pics/inicio/home.jpg'}
            alt="Trabajador soldando"
          />
        </div>
        <div className="flex flex-col justify-center items-left space-y-5 sm:space-y-6 lg:flex-1 lg:w-2/3 lg:p-10 lg:justify-between">
          {isDesktop && <Logo />}
          <p className="text-lg sm:text-xl leading-relaxed text-white md:leading-loose">
            Somos una empresa dedicada a ofrecer un servicio integral y
            personalizado de reparación, mantenimiento y fundición
          </p>
          <Link
            to="/servicios"
            className="button text-center w-full sm:w-2/3 sm:mx-auto lg:mx-0 lg:text-sm xl:w-2/3"
            onClick={goTop}
          >
            Nuestros servicios
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Hero
