import React from 'react'

import Ubication from '../components/Ubication'
import ContactForm from '../components/ContactForm'

const Contact = () => {
  return (
    <div className="wrapper">
      <ContactForm />
      <Ubication />
    </div>
  )
}

export default Contact
