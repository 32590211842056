import React from 'react'
import { Link } from 'react-router-dom'

const PicsNav = () => {
  return (
    <nav className="flex md:block">
      <ul className="flex flex-wrap justify-between items-stretch space-y-2 md:space-y-0 md:space-x-2 md:space-x-3">
        <li className="link service-link">
          <Link to="/servicios/optimizacion" className="inner-link">
            Optimización de plan de mantenimiento
          </Link>
        </li>
        <li className="link service-link">
          <Link to="/servicios/fabricacion-poleas" className="inner-link">
            Fabricación de poleas de hierro fundido y aluminio
          </Link>
        </li>
        <li className="link service-link">
          <Link to="/servicios/fundicion" className="inner-link">
            Fundición
          </Link>
        </li>
        <li className="link service-link">
          <Link to="/servicios/productos-comerciales" className="inner-link">
            Productos Comerciales / Reciclaje
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default PicsNav
