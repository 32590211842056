import { useState } from 'react'
import { Switch, Route } from 'react-router-dom'

import Home from './views/Home'
import About from './views/About'
import Services from './views/Services'
import Contact from './views/Contact'
import Header from './components/Header'
import Footer from './components/Footer'

function App() {
  const [isDark, setIsdark] = useState(false)

  function onDarkChange() {
    setIsdark((isDark) => !isDark)
  }

  return (
    <div className={`min-h-screen ${isDark ? 'dark' : ''} `}>
      <div className="bg-pattern dark:bg-rmf dark:text-white">
        <Header isDark={isDark} onDarkChange={onDarkChange} />

        <div className="pt-20 md:container md:mx-auto md:pt-14 lg:pt-24">
          <Switch>
            <Route exact path="/">
              <Home isDark={isDark} />
            </Route>

            <Route path="/nosotros">
              <About />
            </Route>

            <Route path="/servicios">
              <Services />
            </Route>

            <Route path="/contacto">
              <Contact />
            </Route>
          </Switch>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default App
