import React from 'react'

const Description = () => {
  return (
    <section className="container text-black mx-auto py-10 sm:w-3/4">
      <div className="px-5 space-y-5 md:space-y-8 lg:space-y-5">
        <h3 className="title text-left dark:text-white">Quiénes somos</h3>
        <p className="text-lg leading-relaxed md:text-xl md:leading-relaxed lg:leading-loose dark:text-white">
          RMF Servicios Perú S.R.L. es una empresa con un respaldo de más de 35
          años de formación y experiencia en el rubro Metal Mecánico; orientada
          a la investigación, desarrollo y aplicación de nuevas técnicas en
          reparación, mantenimiento y fabricación.
        </p>
        <img
          className="w-full rounded-3xl mx-auto lg:w-5/6 shadow-2xl"
          src={process.env.PUBLIC_URL + '/img/pics/inicio/frontis-taller.jpg'}
          alt=""
        />
      </div>
    </section>
  )
}

export default Description
