import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { goTop } from '../helpers/go-top'

const Footer = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth >= 1024)

  const updateMedia = () => {
    setDesktop(window.innerWidth >= 1024)
  }

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  return (
    <footer className="theme-color">
      <div className="container mx-auto px-6 pt-10 pb-6">
        <div className="flex flex-wrap">
          {/* Brand */}
          <div className="w-1/2 md:w-2/4 text-left">
            <img
              className="w-11/12 sm:w-3/5 md:w-1/2 lg:w-1/3"
              src={
                process.env.PUBLIC_URL +
                `/img/logos/${
                  isDesktop
                    ? 'horizontal_tagline_on_corporate_2000x899px_by_logaster'
                    : 'vertical_tagline_on_corporate_2000x2000px_by_logaster'
                }.png`
              }
              alt="footer logo"
            />
          </div>
          {/* Links */}
          <div className="w-1/2 md:w-1/4 text-center md:text-left">
            <h5 className="footer-text uppercase mb-6 font-bold">Enlaces</h5>
            <ul className="mb-4">
              <li className="mt-2">
                <Link
                  to="/"
                  className="footer-text footer-link"
                  onClick={goTop}
                >
                  Inicio
                </Link>
              </li>
              <li className="mt-2">
                <Link
                  to="/nosotros"
                  className="footer-text footer-link"
                  onClick={goTop}
                >
                  Nosotros
                </Link>
              </li>
              <li className="mt-2">
                <Link
                  to="/servicios"
                  className="footer-text footer-link"
                  onClick={goTop}
                >
                  Servicios
                </Link>
              </li>
              <li className="mt-2">
                <Link
                  to="/contacto"
                  className="footer-text footer-link"
                  onClick={goTop}
                >
                  Contacto
                </Link>
              </li>
            </ul>
          </div>
          {/* Social */}
          <div className="w-full md:w-1/4 text-center md:text-left md:flex md:flex-col md:justify-between">
            <ul className="inline-flex space-x-20 md:space-x-10 mt-4 mb-6 md:mt-0">
              <li className="mt-2">
                <a
                  href="https://www.facebook.com/rmfser/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="social-link">
                    <i className="fab fa-facebook-f"></i>
                  </span>
                </a>
              </li>
              <li className="mt-2">
                <a
                  href="https://www.linkedin.com/company/rmf-per%C3%BA"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="social-link">
                    <i className="fab fa-linkedin-in"></i>
                  </span>
                </a>
              </li>
              <li className="mt-2">
                <a
                  href="https://www.instagram.com/rmf_peru/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="social-link">
                    <i className="fab fa-instagram"></i>
                  </span>
                </a>
              </li>
            </ul>
            <div>
              <p className="text-sm copy">
                &copy; RMF Servicios Perú SRL - 2021. Todos los derechos
                reservados.
              </p>
              <p className="text-xs mt-5 copy">
                Diseño y construcción:{' '}
                <a href="https://www.linkedin.com/in/zamir-narro/">
                  Develawyer&trade;
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
