import React from 'react'
import { Link } from 'react-router-dom'

import { goTop } from '../helpers/go-top'

const CallToAction = () => {
  return (
    <div className="space-y-5 sm:w-3/4 sm:mx-auto lg:w-1/2">
      <h3 className="text-2xl text-center font-bold mb-2 md:text-4xl">
        Más información
      </h3>
      <p className="text-center">
        Si requieres nuestros servicios o deseas mayor información, con gusto te
        atenderemos
      </p>

      <div className="flex justify-center">
        <Link
          to="/contacto"
          className="button text-center w-full sm:w-2/3 sm:mx-auto lg:mx-0 lg:text-sm xl:w-2/3"
          onClick={goTop}
        >
          Contáctanos
        </Link>
      </div>
    </div>
  )
}

export default CallToAction
