import React from 'react'

const GridPics = ({ path, numOfPics }) => {
  function range(size, startAt = 0) {
    return [...Array(size).keys()].map((i) => i + startAt)
  }

  const iter = range(numOfPics)

  return (
    <div className="grid place-items-center gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-3">
      {iter.map((pic) => {
        return (
          <img
            className="w-full sm:w-60 sm:h-60 object-cover lg:w-80 xl:w-full xl:h-96"
            key={pic}
            src={process.env.PUBLIC_URL + `/img/pics/${path}/${pic}.jpg`}
            alt={`pic ${pic}`}
          />
        )
      })}
    </div>
  )
}

export default GridPics
