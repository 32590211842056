import React from 'react'
import { Switch, Route } from 'react-router-dom'

import PicsNav from '../components/PicsNav'
import InnerPage from '../components/InnerPage'
import GridPics from '../components/GridPics'
import Optimizacion from './products/Optimizacion'
import FabricacionPoleas from './products/FabricacionPoleas'
import Fundicion from './products/Fundicion'
import ProductosComerciales from './products/ProductosComerciales'

const Services = () => {
  return (
    <div className="wrapper w-full mx-auto">
      <h2 className="title">Productos y Servicios</h2>

      <PicsNav />

      <Switch>
        <Route exact path="/servicios">
          <InnerPage
            title="Servicio Especializado para optimización de plan de mantenimiento"
            content=<Optimizacion />
          />
        </Route>

        <Route path="/servicios/optimizacion">
          <InnerPage
            title="Servicio Especializado para optimización de plan de mantenimiento"
            content=<Optimizacion />
          />
        </Route>

        <Route path="/servicios/fabricacion-poleas">
          <InnerPage
            title="Fabricación de poleas de hierro fundido y aluminio"
            pics=<GridPics path="fabricacion-poleas" numOfPics={1} />
            content=<FabricacionPoleas />
          />
        </Route>

        <Route path="/servicios/fundicion">
          <InnerPage
            title="Fundición"
            pics=<GridPics path="fundicion" numOfPics={21} />
            content=<Fundicion />
          />
        </Route>

        <Route path="/servicios/productos-comerciales">
          <InnerPage
            title="Productos comerciales y Reciclado de cobre"
            content=<ProductosComerciales />
          />
        </Route>
      </Switch>
    </div>
  )
}

export default Services
