import React from 'react'

const About = () => {
  return (
    <div className="wrapper space-y-12 w-3/4 mx-auto md:w-5/6 lg:w-4/5 lg:space-y-20 md:pb-12">
      <h2 className="title">Nosotros</h2>

      <section className="flex flex-col space-y-5 lg:flex-row lg:space-y-0 lg:space-x-12">
        <div className="lg:w-1/3">
          <h3 className="subtitle pb-5">MISIÓN:</h3>
          <p className="paragraph">
            "Brindar adecuados Servicios de Ingeniería que permitan dar solución
            a los problemas en reparación, mantenimiento, fabricación y
            servicios en metal mecánica: investigando, desarrollando y aplicando
            procesos de calidad, cuidando la salud y seguridad de todos".
          </p>
        </div>
        <img
          className="rounded-2xl shadow-2xl w-full mx-auto md:w-3/4 lg:w-2/3 lg:m-0"
          src={
            process.env.PUBLIC_URL + '/img/pics/metodologia/reparacion/3.jpg'
          }
          alt="foto mision"
        />
      </section>

      <section className="flex flex-col space-y-5 lg:flex-row-reverse lg:space-y-0">
        <div className="lg:w-1/3">
          <h3 className="subtitle pb-5">VISIÓN:</h3>
          <p className="paragraph">
            Consolidarnos como la mejor empresa del sector ofreciendo un
            servicio integral y optimo con los más altos estándares de calidad.
          </p>
        </div>

        <img
          className="rounded-2xl shadow-2xl w-full mx-auto md:w-3/4 lg:w-3/5 lg:mr-10"
          src={
            process.env.PUBLIC_URL + '/img/pics/metodologia/reparacion/2.jpg'
          }
          alt="foto vision"
        />
      </section>

      <section className="space-y-5 my-5">
        <p className="paragraph">
          <i className="fas fa-chevron-right mr-2"></i>
          Contamos con una infraestructura y maquinarias adecuadas para llevar a
          cabo todos nuestros trabajos con la mejor calidad y la seguridad para
          nuestros clientes.
        </p>
        <p className="paragraph">
          <i className="fas fa-chevron-right mr-2"></i>
          Contamos con aliados estratégicos que nos ayudan a certificar y
          brindar un servicio de mejor calidad para nuestros clientes.
        </p>
      </section>
    </div>
  )
}

export default About
