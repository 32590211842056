import React from 'react'

import GridPics from '../../components/GridPics'

const ProductosComerciales = () => {
  return (
    <div className="space-y-5">
      <h4 className="subtitle">Productos comerciales:</h4>
      <p className="paragraph">
        Somos especialistas en la fabricación de productos y estructuras en
        acero al carbono, acero inoxidable, fabricamos:
      </p>
      <ul>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Cocinas industriales en
          acero inoxidable
        </li>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Foodtruck
        </li>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Parrillas en acero y
          acero inoxidable
        </li>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Cilindros en acero y
          acero inoxidable
        </li>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Inmobiliaria escolar:
          carpetas, mesas, etc
        </li>
        <li>
          <i className="fas fa-chevron-right mr-2"></i> Entre otros productos
          que nuestros clientes soliciten, dándoles la mejor solución a sus
          necesidades
        </li>
      </ul>
      <GridPics path="productos-comerciales/comerciales" numOfPics={10} />

      <h4 className="subtitle">Reciclaje de cobre:</h4>
      <p className="paragraph">
        El reciclaje de cobre es realmente efectivo ya que este material es 100%
        reciclable. Además, más de la mitad del cobre que usamos en nuestras
        distintas tareas proviene de un proceso de reciclaje.
      </p>
      <p className="paragraph">
        Brindamos el servicio de reciclado de cables el cual consiste en lo
        siguiente:
      </p>
      <ol className="list-decimal ml-5">
        <li>Identificar los objetos que únicamente posean cobre y plástico.</li>
        <li>Se cortan y se trituran.</li>
        <li>
          La máquina separa el cobre y el plástico enviándolos a recipientes
          separados para su recolección.
        </li>
        <li>
          Almacena el cobre y plástico obtenido para un posterior proceso de
          reciclaje.
        </li>
        <li>Fundición del cobre en lingotes.</li>
      </ol>
      <GridPics path="productos-comerciales/reciclaje" numOfPics={1} />
    </div>
  )
}

export default ProductosComerciales
