import React from 'react'

const DownloadBtn = ({ title = 'Descargar brochure', path }) => {
  return (
    <a
      className="button block w-max my-2"
      href={process.env.PUBLIC_URL + path}
      download
    >
      <span>
        <i className="fas fa-arrow-circle-down mr-2"></i>
        {title}
      </span>
    </a>
  )
}

export default DownloadBtn
