import React from 'react'

const Logo = () => {
  return (
    <img
      className="w-full rounded-3xl mx-auto md:w-3/4 lg:mx-0 lg:w-full"
      src={
        process.env.PUBLIC_URL +
        '/img/logos/horizontal_tagline_on_corporate_2000x899px_by_logaster.png'
      }
      alt="Logo de la empresa"
    />
  )
}

export default Logo
