// Solution found at:
// https://stackoverflow.com/questions/7449767/how-do-i-send-an-html-form-in-an-email-not-just-mailto?rq=1
// https://github.com/dwyl/learn-to-send-email-via-google-script-html-no-server

export function handleFormSubmit(event, values, errors) {
  // handles form submit without any jquery
  event.preventDefault() // we are submitting via xhr below
  var form = event.target
  var data = values

  const validatedData =
    !!values.firstName && !!values.lastName && !!values.email

  if (!validatedData || Object.keys(errors).length !== 0) return

  var url = form.action
  var xhr = new XMLHttpRequest()
  xhr.open('POST', url)
  // xhr.withCredentials = true;
  xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      var thankYouMessage = form.querySelector('.thankyou_message')
      if (thankYouMessage) {
        thankYouMessage.style.display = 'block'
      }
    }
  }
  // url encode form data for sending as post data
  var encoded = Object.keys(data)
    .map(function (k) {
      return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
    })
    .join('&')
  xhr.send(encoded)
}
