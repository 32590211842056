import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { goTop } from '../helpers/go-top'

const Header = ({ isDark, onDarkChange }) => {
  const [isHidden, setisHidden] = useState(true)

  function handleClick() {
    setisHidden((isHidden) => !isHidden)
  }

  return (
    <header className="fixed w-full nav-color block divide-y divide-rmfAccent-100 h-auto px-3 lg:flex lg:justify-between lg:items-center lg:divide-none lg:h-30 lg:px-6">
      <div className="w-full flex justify-between items-center px-3">
        <h1 className="hidden">RMF Peru</h1>
        <Link to="/" className="focus:outline-none">
          <img
            className="w-2/5 sm:w-1/2"
            src={
              process.env.PUBLIC_URL +
              `/img/logos/smallsize_on_${
                isDark ? 'corporate' : 'transparent'
              }_330x150px_by_logaster.png`
            }
            alt="logo"
          />
        </Link>

        <div className="block md:hidden">
          <button
            className={`flex items-center px-3 py-2 ${
              isDark ? 'text-white' : 'text-rmf'
            } focus:outline-none`}
            onClick={handleClick}
          >
            <i className="fas fa-bars fa-lg"></i>
          </button>
        </div>
      </div>
      <nav className={`${isHidden ? 'hidden' : 'block'} md:block`}>
        <ul className="flex justify-between items-center overflow-x-auto space-x-0 md:space-x-3">
          <li>
            <Link to="/" className="link header-link" onClick={goTop}>
              Inicio
            </Link>
          </li>
          <li>
            <Link to="/nosotros" className="link header-link" onClick={goTop}>
              Nosotros
            </Link>
          </li>
          <li>
            <Link to="/servicios" className="link header-link" onClick={goTop}>
              Servicios
            </Link>
          </li>
          <li>
            <Link to="/contacto" className="link header-link" onClick={goTop}>
              Contacto
            </Link>
          </li>
          <li
            onClick={onDarkChange}
            className="cursor-pointer py-2 px-4 text-rmfAccent-50 hover:text-rmfAccent-100"
          >
            <i className={`fas fa-${isDark ? 'sun' : 'moon'} fa-lg`}></i>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default Header
