import React from 'react'

const Fundicion = () => {
  return (
    <React.Fragment>
      <p className="paragraph">
        Contamos con una capacidad instalada de 3 toneladas Podemos fabricar
        cualquier pieza en material; Hierro fundido, Hierro nodular, Aluminio y
        Bronce adicionalmente podemos fabricar los moldes necesarios para la
        fundición de ser requerido. Ejemplo:
      </p>
      <ul>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Polea Acople
        </li>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Anillos de Tapa de buzón
        </li>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Bujes para poleas
        </li>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Manguitos de acople
        </li>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Piñones
        </li>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Poleas en V
        </li>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Hélices
        </li>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Tapa de pin biela
        </li>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Tazones de bomba
          sumergible
        </li>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Anillos para motores
        </li>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Culata de motor
        </li>
        <li>
          <i className="fas fa-chevron-right mr-2"></i>Impulsores
        </li>
      </ul>
    </React.Fragment>
  )
}

export default Fundicion
